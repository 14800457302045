import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import useAuth from '../hooks/useAuth'

const DashFooter = () => {
    const {finturoUsername } = useAuth()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onGoHomeClicked = () => navigate('/dash')

    let goHomeButton = null
    if (pathname !== '/dash') {
        goHomeButton = (
            <button
                className=""
                title="Home"
                onClick={onGoHomeClicked}
            >
                <FontAwesomeIcon icon={faHouse} />
            </button>
        )
    }

    const content = (
        <footer className="w-full bg-white shadow-sm py-3">
            <div className="max-w-6xl mx-auto px-4 text-sm text-gray-600">
                <div className="text-center space-y-3">
                    <p>
                        By using this website you understand the information being presented is provided for informational purposes only and agree to our{' '}
                        <Link to="/privacy" className="text-MainTheme hover:underline">
                            Privacy Policy
                        </Link>
                        . Nothing in this communication should be constructed as an offer, recommendation, or solicitation to buy or sell any security.
                    </p>
                    
                    <p>
                        All UI screenshots provided are for illustrative purposes only and any performance figures displayed should not be considered representative of actual performance.
                    </p>
                    
                    <p>
                        Investing involves risk, including the possibility of loss of principal. Past performance does not guarantee future success. Historical returns, expected returns, and probability projections are provided for informational and illustrative purposes, and may not reflect actual future performance. Investment Management and advisory services –which are not FDIC insured– are provided by Finturo, Inc ("Finturo"), an SEC-registered investment adviser.
                    </p>
                </div>
            </div>
        </footer>
    )
    return content
}
export default DashFooter