import React, { useState, useImperativeHandle, forwardRef } from "react";

const InputBox = forwardRef(({ type, placeholder, width = "w-full", onValueChange, keyName}, ref) => {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (type === "number") {
      // Remove non-numeric characters except for commas
      let numInp = Number(inputValue.replace(/\D/g, "").replace(/,/g, ""))
      onValueChange && onValueChange({[keyName] : numInp});
      const numericValue = inputValue.replace(/,/g, "").replace(/\D/g, "");
      setValue(formatNumber(numericValue));
    } else {
      onValueChange && onValueChange({[keyName] : inputValue});
      setValue(inputValue);
    }
  };

  const formatNumber = (num) => {
    if (!num) return "";
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const increaseValue = (amount) => {
    if (type === "number") {
      const numericValue = parseInt(value.replace(/,/g, "") || "0", 10);
      setValue(formatNumber(numericValue + amount));
    }
  };

  useImperativeHandle(ref, () => ({
    increaseValue,
  }));

  return (
    <div className={`border-b-2 border-slate-900 ${width}`}>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="text-2xl border-none outline-none bg-transparent w-full placeholder:text-gray-400"
        required
      />
    </div>
  );
});
export default InputBox