import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


import Navbtn from './Navbtn';
import Langbtn from './langbtn';
import logo from './Finturalogo.svg'
// import { icon } from '@fortawesome/fontawesome-svg-core';

const Navbar = () => {
  const { t } = useTranslation()

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  let [open, setOpen] = useState(false);
  const isMobile = (windowWidth <= 767);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight)
    // Update the 'open' state based on the window width
    if (window.innerWidth >= 768 && !open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  useEffect(() => {
    document.title = t('app_title')
  }, [t])

  return (
    <div className={`${open ? 'fixed' : 'fixed  '}  side-galaxy:static w-screen flex z-10 side-galaxy:col-span-2 side-galaxy:row-span-1 bg-gray-200 items-center justify-center  py-1 side-galaxy:py-0 h-16 md:h-18 `}>
      <div className="flex justify-between rounded-xl items-center  bg-white  md:px-10 px-4 " style={{ height: "85%", width: "99%" }}>
        <div className='w-full md:w-1/4 h-full flex justify-between'>
        <Link to="/">
          <div className='font-bold cursor-pointer font-Walsh2 text-gray-800 flex justify-center items-center bg-red-300 relative'>
            <div className=' h-32 w-32  absolute left-0 -top-10'>
              <img className=' ' alt='Finturo logo' src={logo}></img>
            </div>
            {/* <p className="text-center pt-1" style={{ fontSize: '1.875rem' }}>
             Finturo
            </p> */}
          </div>
         </Link>
          <div className='h-full flex items-center cursor-pointer md:hidden' >
            {/* <Link to="/signup">
              <input type='button' value="Get Started" className='text-base cursor-pointer rounded-lg px-2 py-1.5 mr-3 bg-FinanzaGreen text-white font-semibold ring-1 ring-inset ring-gray-300 hover:bg-FinanzaGreenDarker'></input>
            </Link> */}
            <ion-icon onClick={() => setOpen(!open)} style={{ fontSize: '1.875rem' }} name={open ? '' : 'menu'}></ion-icon>
          </div>
        </div>

        {/*desktop version  */}
        <div
          // ease-in
          className={`md:flex md:items-center md:pb-0 pb-12 fixed md:static md:z-auto z-[20] right-0 w-full md:w-auto md:pl-0 
                   duration-1000 
                    ${open ? 'top-0  h-full bg-FinanzaGreen md:bg-white' : ' top-[-800px]'}  `}>
          <div onClick={() => setOpen(!open)} className=' absolute right-8 top-3.5 cursor-pointer md:hidden' style={{ fontSize: '1.875rem' }}>
            <ion-icon name={open ? 'close' : ''}></ion-icon>
          </div>
          {/* mobile burger clicked   */}
          <div className={`${open ? 'flex flex-col pt-10 gap-y-8 items-center relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4' : 'flex items-center'} `} >
          {/* <Link to="/signup" className="hover:text-gray-700">
            <input type='button' value="Get Started" className={` ${open ? ' text-2xl border-2 bg-white border-white px-16 rounded-xl py-1' : 'inline-flex justify-center gap-x-1.5 rounded-md mr-1 bg-FinanzaGreen px-3 py-1.5  text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-FinanzaGreenDarker'} font-semibold cursor-pointer`} >
            </input>
          </Link> */}
            <Langbtn></Langbtn>
            <Link to="/FAQ" className={`${open ? '' : ''} hover:text-gray-700`}>
              <input type='button' value={open ? 'Frequently Asked Questions' : 'FAQ'} className={`${open ? ' text-2xl text-gray-950' : 'inline-flex justify-center gap-x-1.5 rounded-md mr-1 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 '} font-semibold cursor-pointer`}>
              </input>
            </Link>

            <Link to="/login" className="">
              <div className={` ${open ? 'relative inline-flex items-center justify-center p-0 mb-2 mr-2 overflow-hidden font-medium text-gray-900 rounded-xl group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 border-2 border-slate-900 text-2xl   rounded-xl  ' : 'inline-flex justify-center gap-x-1.5 rounded-md mr-1 bg-white px-3 py-1.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 transition duration-500 hover:ease-in hover:bg-gray-50 '} font-semibold bg-FinanzaOrange`} >
                <span class={` ${open ? 'relative px-24 py-1 transition-all ease-in duration-200 bg-FinanzaOrange dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 group-hover:origin-right' : ''}`}>
                  <input className='cursor-pointer' type='button' value='Login'>
                  </input>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>

  );

}

export default Navbar