import { apiSlice } from "../../app/api/apiSlice";

export const monteApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        postSim: builder.mutation({
            query: (simParams) => ({
                url: "/simulation/monte-carlo",
                method: "POST",
                body: simParams,
            }),
        }),
    }),
});

export const { usePostSimMutation } = monteApiSlice;
