import React from "react";
import { useState } from "react"

import { useUpdateUserMutation } from "../../../../features/users/usersApiSlice"

import { faBagShopping, faBolt, faCar, faHouse, faMoneyBill, faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NextButton from "./NextButton";


const Setexpenses = ({handleAnswer, question}) => {

  const Components = {
    "house": faHouse,
    "money": faMoneyBill,
    "grocery": faBagShopping,
    "utility": faBolt,
    "transport": faCar
  }

  const defaultExp = [
    { id: 0, name: "Rent/Mortage", icon: "house", amount: 0 },
    { id: 1, name: "Groceries", icon: "grocery", amount: 0 },
    { id: 2, name: "Utilities", icon: "utility", amount: 0 },
    { id: 3, name: "Gas/Bus", icon: "transport", amount: 0 },
  ];

  const [updateUser, {
    isLoading,
    isSuccess,
    isError,
    error
 }] = useUpdateUserMutation()

  const [expenseTtl, setExpenseTtl] = useState(0);
  // const [expenseAmt, setExpenseAmt] = useState(Array(Object.keys(defaultExp).length).fill(0))
  const [expenses, setExpenses] = useState(defaultExp)
  const [newExp, setNewExp] = useState(false);
  const [cusInp, setCusInp] = useState("")

  const changeFlag = () => {
    setNewExp(true)
  }

  const createCard = () => {
    // const input = e.target.value
    // console.log(input)
    if (cusInp != "") { // ensure user types something in before submit
      const newId = Object.keys(expenses).length
      let newExp = JSON.parse(JSON.stringify(expenses))
      newExp.push({ id: Number(newId), name: cusInp, icon: "money", amount: 0 })
      console.log(newExp)
      setExpenses(newExp)
      setCusInp("")
      setNewExp(false)
    }

  }

  const handleChange = (id) => (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      let newExp = JSON.parse(JSON.stringify(expenses))
      newExp[id].amount = Number(inputValue)
      let curTotal = 0;
      for (let i = 0; i < (Object.keys(newExp).length); i++) {
        curTotal += Number(newExp[i].amount)
      }
      setExpenseTtl(curTotal)
      setExpenses(newExp)
    }
  }

  const customInput = (e) => {
    const inputValue = e.target.value;
    setCusInp(inputValue)
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Pass the answer to the parent component
   
    await updateUser({
      invData : {
      expenses: {
        allExp: expenses,
        totExp: Number(expenseTtl)
      }
    } 
    })
    
    
    const answer = {
      done: question.next,
   }
    handleAnswer(answer)
  };

  const defCard = (
    <div className=" m-auto flex border 
    items-center p-4 w-[90%] h-20  rounded-xl ">
      <div
        onClick={changeFlag}
        className="w-12 h-12 rounded-full border border-4 flex justify-center items-center">
        <FontAwesomeIcon icon={faPencil} className="pb-0.5 text-FinanzaGreen h-5" />
      </div>
      <p className="ml-1.5 text-md">Add your own!</p>
    </div>)

  const addCard = (
    <div className=" m-auto flex border 
  items-center p-4 w-[90%] h-20  rounded-xl ">
      <div
        onClick={createCard}
        className="w-12 h-12 rounded-full border border-4 flex justify-center items-center">
        <FontAwesomeIcon icon={faPlus} className="pb-0.5 text-FinanzaGreen h-5" />
      </div>
      <input className="w-24 border-b-2 border-slate-900 ml-1.5
     "
        // TODO change focus ring color 
        placeholder="Change me"
        onChange={customInput}
        value={cusInp}
      >
      </input>
    </div>)


  return (
    <>
    <div className="w-full ">

      <form onSubmit={handleSubmit}> 
      <div className="w-full flex justify-center items-ceter">
        <p className="text-4xl font-bold"> {`Total $${expenseTtl}`} </p>
      </div>
      <div className="my-4">
        <p className=" text-center text-xl font-slate-600">Set your <span className="text-FinanzaGreen">monthly</span> expense below:</p>
      </div>
      <div className="h-[27rem] w-full overflow-auto 
       rounded-lg p-2 space-y-3  inline-block text-center ">
        {expenses.map((expense) => (
          <div key={expense.id} className=" m-auto flex border 
          items-center p-4 w-[90%] h-20  rounded-xl">
            <div className="w-12 h-12 rounded-full border border-4 flex justify-center items-center">
              <FontAwesomeIcon icon={Components[expense.icon]} className="pb-0.5 text-FinanzaGreen h-5" /> </div>
            <p className="ml-1.5 text-md">{expense.name}</p>
            <input className="w-16 h-10 text-slate-600 ml-auto pl-2"
              value={expense.amount}
              type="text"
              onChange={handleChange(expense.id)}
            />
          </div>
        ))}
        <div >
          {newExp ? addCard : defCard}
        </div>
      </div>
      <div className="w-full flex justify-center pt-4">
                    <NextButton />
        </div>
      </form>
      </div>
    </>

  )
}
export default Setexpenses