import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    // Get the token from the URL hash
    const token = location.hash.slice(1); // Remove the # symbol

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (newPassword.length < 8) {
            setError('Password must be at least 8 characters long');
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/password/reset/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newPassword
                }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.detail?.message || 'Failed to reset password');
            }

            setIsSuccess(true);
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            setError(error.message || 'Failed to reset password. The link may have expired.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!token) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4">
                <div className="max-w-md w-full text-center">
                    <h2 className="text-2xl font-semibold text-gray-900 mb-4">Invalid Reset Link</h2>
                    <p className="text-gray-600">
                        This password reset link is invalid or has expired.
                        Please request a new password reset link.
                    </p>
                    <button
                        onClick={() => navigate('/password/forgot')}
                        className="mt-4 text-blue-600 hover:text-blue-800"
                    >
                        Request New Reset Link
                    </button>
                </div>
            </div>
        );
    }

    if (isSuccess) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4">
                <div className="max-w-md w-full text-center">
                    <h2 className="text-2xl font-semibold text-gray-900 mb-4">Password Reset Successful</h2>
                    <p className="text-gray-600">Your password has been reset. Redirecting to login...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4">
            <div className="max-w-md w-full">
                <h2 className="text-2xl font-semibold text-center text-gray-900 mb-8">
                    Reset Your Password
                </h2>
                <form onSubmit={handleSubmit}>
                    {error && (
                        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
                            {error}
                        </div>
                    )}
                    <input
                        type="password"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                        placeholder="New password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                        {isLoading ? 'Resetting...' : 'Reset Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;