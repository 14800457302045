import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials } from '../../features/auth/authSlice'



const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token
        console.log('==== Base Query Debug ====');
        console.log('Base URL:', process.env.REACT_APP_BASE_URL);
        console.log('Token:', token);

        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

// handes refresh token logic

const baseQueryWithReauth = async (args, api, extraOptions) => {
    console.log('==== Request Start ====');
    console.log('Request Args:', args);

    try {
        let result = await baseQuery(args, api, extraOptions);
        console.log('==== Request Result ====');
        console.log('Raw Response:', result);

        if (result.error) {
            console.log('Error in response:', result.error);
        }

        return result;
    } catch (err) {
        console.log('==== Request Failed ====');
        console.log('Error:', err);
        throw err;
    }
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Note', 'User'],
    endpoints: builder => ({})
})
