import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Value from "./ValueStatement";
import Testimonials from "./Testimonials";
import OldSignUp from "./OldSignUp";
import DashFooter from '../DashFooter';

import bar2 from './img/bar2.png';

// better for simpler things, no state requirements
// function HomeList() {
//     return "Welcome to Finanza"
// } 

// if you have states recommended you use this

const phoneGrid = {
  gridTemplateRows: "17% 18% 55% 10%"
}

const phoneStyling = {
  height: "95%",
  width: "98%",
  // other styles
};

const phoneStylingMobile = {
  height: "95%",
  width: "98%",
  // width: "100%",
  // other styles
};




// Add this bubble animation component
const Bubbles = () => {

  const [randomNumber] = useState(() => Math.random() * 100 + 50);

  return (
    <div className="fixed inset-0 pointer-events-none">
      {[...Array(10)].map((_, i) => {
        const randomSize = Math.random() * 100 + 50;

        return (
          <div
            key={i}
            className={`absolute rounded-full bg-MainTheme/10 animate-float-${i + 1}`}
            style={{
              width: `${randomSize}px`,
              height: `${randomSize}px`,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${Math.random() * 10 + 10}s`,
            }}
          />
        );
      })}
    </div>
  );
};

export default function HomePage() {
  const { t } = useTranslation();

  

  return (
    <div>
      <div className="flex flex-col min-h-screen relative">
        {/* Background decorative elements */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Top-right blob */}
          <div className="absolute -top-20 -right-20 w-96 h-96 bg-MainTheme/10 rounded-full blur-3xl"></div>
          {/* Bottom-left blob */}
          <div className="absolute -bottom-20 -left-20 w-96 h-96 bg-MainTheme/5 rounded-full blur-3xl"></div>
          {/* Subtle middle accent */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-96 bg-MainTheme/5 rounded-full blur-3xl"></div>
          
        
        </div>

        {/* Main content */}
        <div className="flex-1 flex flex-col relative">
          <Bubbles />
          <Navbar />
          <main className="flex-1 flex items-center justify-center px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-6xl flex flex-col md:flex-row md:items-center md:justify-between md:gap-8 backdrop-blur-sm">
              {/* Text content */}
              <div className="text-center md:text-left md:flex-1">
                <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl">
                  Your own financial advisor
                </h1>
                <h2 className="text-3xl font-bold text-MainTheme sm:text-4xl md:text-5xl mt-2">
                  for just $7 a month
                </h2>
              </div>

              {/* Form content */}
              <div className="mt-8 md:mt-0 md:flex-1 max-w-md mx-auto md:max-w-none">
                <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-6 shadow-lg">
                  <form className="space-y-4">
                    <div>
                      <input
                        type="email"
                        required
                        className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-MainTheme focus:border-MainTheme focus:z-10 sm:text-sm"
                        placeholder="Waitlist Coming Soon"
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-MainTheme hover:bg-MainTheme/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-MainTheme transition-all duration-200 shadow-md hover:shadow-lg"
                      >
                        Waitlist Coming Soon
                      </button>
                    </div>
                  </form>

                  <div className="text-center cursor-pointer group mt-4">
                    <div className="text-gray-600 hover:text-MainTheme transition-colors duration-200 flex items-center justify-center gap-2">
                      <p className="text-sm font-medium">Learn More</p>
                      <FontAwesomeIcon 
                        icon={faChevronDown} 
                        className="h-4 w-4 animate-bounce"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <DashFooter />
    </div>
  );
}