import { useState, useEffect } from "react";
import PlanOutcomes from "./PlanOutcomes";

const SetPlan = () => {
  const [dots, setDots] = useState("");
  const [progress, setProgress] = useState(0);
  const [bgHeight, setBgHeight] = useState(0);
  const [showHelloWorld, setShowHelloWorld] = useState(false);

  useEffect(() => {
    const dotInterval = setInterval(() => {
      if (showHelloWorld) return; // don't waste resources intervalling after 
      setDots((prev) => (prev === "..." ? "" : prev + "."));
    }, 500);

    return () => clearInterval(dotInterval);
  }, [showHelloWorld]);

  useEffect(() => {
    if (showHelloWorld) return; 
    const progressInterval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 20));
      setBgHeight((prev) => {
        if (prev >= 100) {
          setShowHelloWorld(true); // Show "Hello World" after reaching the top
          return 0;
        }
        return prev + 20;
      });
    }, 1000);

    return () => clearInterval(progressInterval);
  }, [showHelloWorld]);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {/* Rising Green Background */}
      <div
        className="absolute bottom-0 left-0 w-full bg-MainTheme transition-all duration-1000 z-20"
        style={{ height: `${showHelloWorld ? 0 : bgHeight }%` }}
      ></div>

      <div className="flex flex-col items-center justify-center w-full relative z-10">
        {(showHelloWorld) ? (
         <PlanOutcomes/>
        ) : (
          <div className="relative">
            <div className="w-36 h-36 bg-MainTheme border border-black rounded-full flex justify-center items-center">
              {/* Inner Ring */}
              <svg className="absolute" width="128" height="128" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="46"
                  fill="none"
                  stroke="black"
                  strokeWidth="4"
                  strokeDasharray="289"
                  strokeDashoffset={(1 - progress / 100) * 289}
                  strokeLinecap="round"
                  transform="rotate(-90 50 50)" // Start from the top
                />
                <text
                  x="50"
                  y="55"
                  textAnchor="middle"
                  fontSize="16"
                  fontWeight="bold"
                  fill="black"
                >
                  {progress}%
                </text>
              </svg>
            </div>
          </div>
        )}

        {!showHelloWorld && (
          <div className="w-72 mt-32 text-center flex relative z-10">
            <div className="w-full flex flex-col">
              <p className="text-5xl">Building your financial</p>
              <div className="flex text-center">
                <p className="text-5xl pl-20">future</p>
                <p className="text-5xl"> {dots} </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SetPlan;
