import { faDollar, faMoneyBill, faQuestion, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Line } from "react-chartjs-2";
import { usePostSimMutation } from "../../../../features/users/monteApiSlice";
import NextButton from "./NextButton";
import { getCurQnairre } from "../../../../features/users/qnairreSlice";

import { timeUntil } from "../../../helperfuncs";

import { useSelector } from "react-redux";
import { useState } from "react";

const PlanOutcomes = () => {
    const qnaire = useSelector(getCurQnairre)

    // TOD0 set  variable for current goal position in array in redux store 
    const monthsInp = qnaire.goals[0].goal1.targetMonth
    const yearsInp = qnaire.goals[0].goal1.targetYear 
    // asummes date input is always in the future 
    let {years, months } = timeUntil(monthsInp,yearsInp) // let user adjust this later 

    const lumpStep = 10 // step size on lump sum value 
    const [PostSim,{isLoading, isSuccess,isError,error}] = usePostSimMutation()
    const [lumpSum, setLumpSum] = useState(100); // TODO: should be based on some budget criteria

    const handleLumpSliderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setLumpSum(newValue);
        
        // Optionally trigger simulation update
        const simParams = {
            "monthly": 0,
            "years": 5,
            "initial_investment": 10000,
            "risky_allocation": 0.8,
            "recurrent_investment": 0
        }
        PostSim(simParams)
            .then(response => console.log("Simulation updated:", response))
            .catch(err => console.error("Simulation error:", err));
    };

    const MainTheme = '#84CC7E'
    const DarkerTheme = '#67a162'
    // format data in charts

    const chartData = {
        // have the simulation service return the month & date 
        labels: [`Today`,  "2028", "2029", `Jan 2030`],
        datasets: [
            {
                borderColor: 'rgb(244, 117, 117)',
                backgroundColor: 'rgb(244, 117, 117)',
                data: [0, 50, 100, 200],
                pointRadius: [1, 1, 1, 7], // Increase the size of the first and last data points
             //   pointBorderColor: ['black', 'black', 'black', 'rgba(200, 0, 0, 0.9)'], // Custom border colors for each point
                pointBorderWidth: [3, 2, 2, 0], // Thickness of the border for each point
                label: 'Investment value',
            },
            {
                borderColor: MainTheme,
                backgroundColor: MainTheme,
                data: [0, 400, 500, 600],
                pointRadius: [1, 1, 1, 9], // Increase the size of the first and last data points
                // pointBorderColor: [MainTheme, MainTheme, MainTheme, 'rgba(200, 0, 0, 0.9)'], // Custom border colors for each point
                pointBorderWidth: [3, 2, 2, 0], // Thickness of the border for each point
                label: 'Investment value',
            },
            {
                borderColor: DarkerTheme,
                backgroundColor: DarkerTheme,
                data: [0, 300, 950, 1000],
                pointRadius: [1, 1, 1, 7], // Increase the size of the first and last data points
                // pointBorderColor: [, 'black', 'black', 'rgba(200, 0, 0, 0.9)'], // Custom border colors for each point
                pointBorderWidth: [3, 2, 2, 0], // Thickness of the border for each point
                label: 'Investment value',
            }
        ]
    }

    const options = {
        maintainAspectRatio: false,
        // layout: {
        //   padding: {
        //     top:
        //   }
        // },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    display: false, // Hide x-axis gridlines
                },
                ticks: {
                    font: {
                        weight: 'bold', // Make x-axis text bold
                        size: 14,        // Make x-axis text larger
                    },

                },
            },
            y: {
                beginAtZero: false,
                min: 0,
                // maintainAspectRatio: false,
                position: 'right',
                grid: {
                    display: false, // Hide y-axis gridlines
                },
                ticks: {
                    font: {
                        weight: 'bold', // Make x-axis text bold
                        size: 14,        // Make x-axis text larger
                    },
                    //   callback: function(value, index, values) {
                    //     // Customize which values appear on the y-axis
                    //     if (value === 1000 || value === 800) {
                    //       return `$${value}`; // Show specific values as labels with a "$" prefix
                    //     }
                    //     return null; // Hide other tick values
                    //   },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
                // display: true, // Hide the legend
                labels: {
                    usePointStyle: true, // Use point style for legend items
                    font: {
                        // weight: 'bold', // Make the legend text bold
                        size: 12,
                    }
                }
            },
        },
    };

    return (
        <div className="w-screen">
            <div>
                <p className="text-3xl font-bold text-center">Possible Plan Outcomes</p>
            </div>
            <div className="w-screen h-80 mt-12 border border-gray-300">
                <Line data={chartData} options={options} />
            </div>
            <div className="w-full flex flex-col items-center">
                <p className="py-5"> Adjusting below affects outcomes</p>
                <div className="flex w-full px-4">
                    {/* One Time Box */}
                    <div className="pl-4 flex flex-col">
                        <div className="flex items-center ">
                            <FontAwesomeIcon icon={faSackDollar} className=" pr-1" />
                            <p className="pr-2 text-lg"> One-time </p>
                            <div className="w-5 h-5 border-2 border-black rounded-full flex items-center justify-center">
                                <FontAwesomeIcon icon={faQuestion} />
                            </div>
                        </div>
                        <div className=" mt-4 mb-6 ">
                        <p className="text-lg font-bold"> {`${lumpSum}`}</p>
                            <div className="border-b-2 border-slate-900 w-24 shadow-[0_2px_4px_rgba(0,0,0,0.9)]">
                            </div>
                        </div>
                        <div className="">
                            {/* Lump Sum Slider */}
                            <input
                                type="range"
                                min="0"
                                max={`1000`}
                                step={lumpStep}
                                defaultValue={lumpSum}
                                onChange={handleLumpSliderChange}
                                className="w-36 h-6 rounded-xl bg-gradient-to-r from-slate-100 to-green-500 accent- bg-transparent"
                                placeholder="Select an investment value"
                                style={
                                    {
                                        WebkitAppearance: 'none',
                                        MozAppearance: 'none',
                                        // overflowY: 'auto',
                                        // background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
                                    }
                                }
                            />
                        </div>
                    </div>
                    {/* Monthly Input Box */}
                    <div className="ml-14 w-36 flex flex-col">
                        <div className="flex items-center ">
                            <FontAwesomeIcon icon={faMoneyBill} className=" pr-1" />
                            <p className="pr-2 text-lg"> Monthly </p>
                            <div className="w-5 h-5 border-2 border-black rounded-full flex items-center justify-center">
                                <FontAwesomeIcon icon={faQuestion} />
                            </div>
                        </div>
                        <div className=" mt-4 mb-6 ">
                        <p className="text-lg font-bold"> $3000</p>
                            <div className="border-b-2 border-slate-900 w-24 shadow-[0_2px_4px_rgba(0,0,0,0.9)]">
                            </div>
                        </div>
                        <div className="">
                            <div className="flex">
                            {/* Slider */}
                            <input
                                type="range"
                                min="1"
                                max={`10`}
                                step="1"
                                // defaultValue={sliderValue}
                                //   onChange={handleSliderChange}
                                className="w-28 h-6 rounded-xl bg-gradient-to-r from-slate-100 to-green-500 accent- bg-transparent"
                                placeholder="Select an investment value"
                                style={
                                    {
                                        WebkitAppearance: 'none',
                                        MozAppearance: 'none',
                                        // overflowY: 'auto',
                                        // background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
                                    }
                                }
                            />
                            <p className="text-sm pl-0.5"> $3000</p>
                            </div>
                            <p className="text-sm"> based on your budget</p>
                        </div>
                    </div>
                </div>
            <div className="flex flex-col w-full items-center mt-12 gap-y-6">
                <button className="border-b-2 border-slate-900 ">
                  <p className="text-xl underline decoration-2"> Disclosures</p>
                </button>
                <NextButton/>
            </div>
            </div>

        </div>
    )
}
export default PlanOutcomes