import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import questionnaire from "./questions"
import QnnaireForm from "./QnnaireForm";
import Intro from "./Qnairre/Intro";

import { useGetQnairreQuery, useUpdateQnairreMutation } from "../../../features/users/qnairreApiSlice";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const QnnaireParent = () => {
  // get Qnairre data from get req to handle refresh case 

  let {
    data: qnaire,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetQnairreQuery('userQnairre', {
    pollingInterval: 0,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false
  });

  const [updateQnairre] = useUpdateQnairreMutation()
  const navigate = useNavigate()

  let content

  if (isLoading) {
    content = <div>
      <p> loading... </p>
    </div>
  } else if (isError) {
    content = <div>
      <p>Error loading questionaire. Please try again.</p>
    </div>
  } else if (isSuccess && qnaire?.reqQs) {
    let curQ = (qnaire.reqQs.done === "done") ? ("done") : qnaire.reqQs.done // edge case on last question
    // Number(qnaire.reqQs.done)

    const handleAnswer = async (answer) => {

      function updateObjectInArray(array, newObject) {
        // Extract the key and value from the newObject
        const [newKey, newValue] = Object.entries(newObject)[0];

        // Find the index of the object with the matching key
        const index = array.findIndex(obj => Object.keys(obj)[0] === newKey);

        if (index !== -1) {
          // Update the object at the found index
          array[index][newKey] = { ...array[index][newKey], ...newValue };
        } else {
          // If not found, you can add the new object to the array
          array.push(newObject);
        }

        return array;
      }
      const updatedAnswers = (prevAnswers) => {
        const index = prevAnswers.goals.findIndex(obj => Object.keys(obj)[0] === "goal1");
        const newobj = (index === -1) ? { "goal1": {} } : { ...prevAnswers.goals[index]["goal1"], ...answer.newQs }
        console.log("newobj is", newobj, "test", prevAnswers.goals)
        let copy = [newobj]
        if (index !== -1) {
          copy = JSON.parse(JSON.stringify(prevAnswers.goals));
          copy[index]["goal1"] = newobj
        }
        // prevAnswers.goals[index]["goal1"] = newobj

        return {
          // ...prevAnswers,
          // ...answer,
          done: answer.done,
          goals: copy
        };
      };

      // Save the most recent answer
      let prevQnaire = qnaire.reqQs

      // patchDB 
      try {
        await updateQnairre(updatedAnswers(prevQnaire));
      } catch (error) {
        console.error("Error updating questionnaire:", error);
      }

      // handle navigation
      if (curQ !== "done") {
        console.log("you're not done")
      } else {
        console.log("all done")
        navigate('/home')
      }
    };

    const noHeaderScreens = ["start", "createplan"]

    let navbar = (curQ === "start") ? (
      <>
        <div className="h-8 ">
        </div>
      </>
    ) :
      <>
        <div className="w-full h-fit px-4 flex justify-end">
          <div>
            <FontAwesomeIcon icon={faBars} className="h-8 py-2.5" />
            {/* <p> hI</p> */}
          </div>
        </div>
      </>

    content =
      (
        <>
          <div>
            {navbar}
            {/* Qnairre 'navbar' */}
            <div>
              {(noHeaderScreens.includes(curQ)) ? <></> :
                <div className="flex flex-col h-20  items-center justify-center">
                  <div className="w-full flex justify-center items-center  ">
                    <p className="text-2xl text-FinanzaGreen">{`Part ${questionnaire[curQ].partNum}: ${questionnaire[curQ].part}`}</p>
                  </div>
                  {/* Horizontal ellipses */}
                  <div className="flex space-x-2 mt-2">
                    {Array.from({ length: 3 }, (_, index) => (
                      <div
                        key={index}
                        className={`h-4 w-16 rounded-full ${index + 1 == questionnaire[curQ].partNum
                            ? "bg-green-500"
                            : "bg-gray-300"
                          }`}
                      ></div>
                    ))}
                  </div>
                </div>
              }
            </div>
            <div className={`${(curQ !== "createplan") ? "px-8" : "px-0"} w-full flex flex-col items-center mt-12`}>
              <QnnaireForm
                question={questionnaire[curQ]}
                handleAnswer={handleAnswer}
              />
            </div>
          </div>
        </>
      )
  }

  return (content)
}
export default QnnaireParent