import React, { useState } from 'react';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/password/forgot`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ accountEmail: email }),
            });

            if (!response.ok) {
                throw new Error('Failed to send reset email');
            }

            setIsSubmitted(true);
        } catch (error) {
            setError('Failed to send reset email. Please try again.');
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isSubmitted) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4">
                <div className="max-w-md w-full text-center">
                    <h2 className="text-2xl font-semibold text-gray-900 mb-4">Check Your Email</h2>
                    <p className="text-gray-600">
                        If an account exists with this email address, you will receive password reset instructions.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4">
            <div className="max-w-md w-full">
                <h2 className="text-2xl font-semibold text-center text-gray-900 mb-8">
                    Forgot Password
                </h2>
                <form onSubmit={handleSubmit}>
                    {error && (
                        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
                            {error}
                        </div>
                    )}
                    <input
                        type="email"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                        {isLoading ? 'Sending...' : 'Send Reset Instructions'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;