import InputBox from "./InputBox"
import NextButton from "./NextButton";

import { useUpdateUserMutation } from "../../../../features/users/usersApiSlice"

import { useState } from "react";

const Setincome = ({handleAnswer, question}) => {
  // updating user's invdata logic 
  const [updateUser, {
    isLoading,
    isSuccess,
    isError,
    error
 }] = useUpdateUserMutation()

  const payFrequency = ['bi-weekly', 'weekly', 'monthly']
  const [monthlyIncome, setMonthlyIncome] = useState(0)
  const [selectedValue, setSelectedValue] = useState(payFrequency[0]);

  const handleFreqChange = (e) => {
    const input = e.target.value;
    setSelectedValue(input)
   }
   
   // helper function
  function calcMonInc(inc, freq) {
    let newInc = inc
    switch (freq) {
      case 'bi-weekly':
        newInc = (Number(newInc) * 2)
        break 
      case 'weekly':
        newInc = (Number(newInc) * 4)
        break
      case 'monthly':
        newInc = (Number(newInc))
        break 
    }
    return newInc
  }

  const incomeChange =
  (newValue) => {
    // new input form 
    setMonthlyIncome( newValue["monthlyIncome"] )
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedGoal = {
      "monthlyIncome": calcMonInc(monthlyIncome, selectedValue)
    }

    await updateUser({
      invData : {
      budget: updatedGoal
    } 
    })

    const answer = {
      done: question.next,
    }
    handleAnswer(answer)
    // setAnswer({}); // Clear the input for the next question
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <p className="text-4xl">
              We need your monthly income to create an accurate investment
              plan:
            </p>
            {/* onValueChange={} */}
          </div>
          <div className="flex pt-8">
            <p className="text-3xl pr-4 ">I get paid </p>
            <InputBox type="number" placeholder="10,000" width="w-32" keyName="monthlyIncome" onValueChange={incomeChange} />
          </div>
          <div>
            <select value={selectedValue} onChange={handleFreqChange} className="shadow-md h-20 w-48 rounded-xl text-2xl flex bg-white border px-6 
        text-gray-500 block  border-slate-400 mt-4"
              style={{
                appearance: 'none',
                overflowY: 'auto',
                background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
              }}>
              {payFrequency.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            <p className="text-3xl mt-3">after taxes </p>
          </div>
          <div className="w-full flex justify-center pt-32">
            <NextButton />
          </div>

        </form>

      </div>
    </>
  )
}
export default Setincome