export function getMonthNumber(monthName) {
    
    const monthMap = {
      january: 1,
      february: 2,
      march: 3,
      april: 4,
      may: 5,
      june: 6,
      july: 7,
      august: 8,
      september: 9,
      october: 10,
      november: 11,
      december: 12,
    };
  
    return monthMap[monthName.toLowerCase()] || null; // Return null if not found
  }

  export function timeUntil(monthStr, targetYear) {
    const targetMonth = getMonthNumber(monthStr) - 1; // Convert to 0-based index
    if (targetMonth === null) {
        throw new Error("Invalid month name");
    }

    // Get today's date
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based index

    // Calculate year and month difference
    let yearDiff = targetYear - currentYear;
    let monthDiff = targetMonth - currentMonth;

    // Adjust if monthDiff is negative
    if (monthDiff < 0) {
        yearDiff -= 1;
        monthDiff += 12;
    }

    return { years: yearDiff, months: monthDiff };
}
  