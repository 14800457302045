import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"
import { setInvData } from "./allocSlice";
import useAuth from "../../hooks/useAuth";

// const usersAdapter = createEntityAdapter({})

// const initialState = usersAdapter.getInitialState()

export const allocApiSlice = apiSlice.injectEndpoints(
    {
    endpoints: (builder) => ({
        getAlloc: builder.query({
            query: () => '/investment/allocation',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        //     transformResponse: responseData => {
        //         const loadedUsers = responseData.map(user => {
        //             user.id = user._id
        //             return user
        //         });
        //         return usersAdapter.setAll(initialState, loadedUsers)
        //     },
            providesTags: ['Alloc']
        }),
        // addNewUser: builder.mutation({
        //     query: initialUserData => ({
        //         url: '/users',
        //         method: 'POST',
        //         body: {
        //             ...initialUserData,
        //         }
        //     }),
        //     invalidatesTags: [
        //         { type: 'User', id: "LIST" }
        //     ]
        // }),
        // getQnairre: builder.query({
        //     query: (id) => '/users/qnnaire',
        //     providesTags: (result, error, id) => [{ type: 'User', id }],
        // }),
        updateAlloc: builder.mutation({
            query: (alloc) => 
            {
                // const queryParams = new URLSearchParams(alloc).toString();
                const queryParams = alloc
                return {
                url: `/investment/allocation${queryParams}`,
                method: 'POST',
                body: {
                    // "reqQs": newQs
                }
            }},
            invalidatesTags: ['Alloc'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    // data.message is a string you want it an object
                    const { data } = await queryFulfilled
                    const invData = data.retObj.invData // has to be in this format for setQnairre to work properly
                    console.log("obj being set: ", invData)
                    dispatch(setInvData({invData}))
                } catch (err) {
                    console.log("error is", err)
                }
            }
        }),
    }),
})

export const {
    // useGetQnairreQuery,
    // useAddNewUserMutation,
    useUpdateAllocMutation,
} = allocApiSlice

