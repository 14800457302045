import React, { useState } from "react";
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPepperHot, faShield } from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";

import { useUpdateAllocMutation } from "../../../../features/users/allocApiSlice.js";
import { getCurQnairre } from "../../../../features/users/qnairreSlice.js";
import NextButton from "./NextButton.js"

import { getMonthNumber } from "../../../helperfuncs.js";

const RiskIntro = ({ handleAnswer, question }) => {

  const currentDate = new Date();

  // Extract month and year
  const month = currentDate.toLocaleString("default", { month: "short" }); // Full month name (e.g., January)
  const year = currentDate.getFullYear(); // Current year

  // Safe access to qnaire and goals
  const qnaire = useSelector(getCurQnairre)
  const goals = qnaire?.goals || []

  // Safely find the goal data
  const goalData = goals.find(obj => obj?.goal1)?.goal1 || {}
  const goalYear = goalData.targetYear || year
  const goalMonth = goalData.targetMonth ?
      getMonthNumber(goalData.targetMonth.toLowerCase()) :
      currentDate.getMonth() + 1

  const [selected, setSelected] = useState(null);
  const [riskScore, setRiskScore] = useState(3); // Default value

  // chart data 
  const chartData = {
    labels: [`${month} ${year}`, "", "", `${month} ${year + 1}`],
    datasets: [
      {
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        data: [1000, 900, 950, 800],
        pointRadius: [6, 1, 1, 6], // Increase the size of the first and last data points
        pointBorderColor: ['rgba(200, 0, 0, 0.9)', 'black', 'black', 'rgba(200, 0, 0, 0.9)'], // Custom border colors for each point
        pointBorderWidth: [3, 2, 2, 3], // Thickness of the border for each point
        label: 'Investment value',
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    // layout: {
    //   padding: {
    //     top:
    //   }
    // },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hide x-axis gridlines
        },
        ticks: {
          font: {
            weight: 'bold', // Make x-axis text bold
            size: 14,        // Make x-axis text larger
          },
          
        },
      },
      y: {
        beginAtZero: false,
        min: 400,
        // maintainAspectRatio: false,
        position: 'left',
        grid: {
          display: false, // Hide y-axis gridlines
        },
        ticks: {
          font: {
            weight: 'bold', // Make x-axis text bold
            size: 14,        // Make x-axis text larger
          },
          callback: function(value, index, values) {
            // Customize which values appear on the y-axis
            if (value === 1000 || value === 800) {
              return `$${value}`; // Show specific values as labels with a "$" prefix
            }
            return null; // Hide other tick values
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        // display: true, // Hide the legend
        labels: {
          usePointStyle: true, // Use point style for legend items
          font: {
            // weight: 'bold', // Make the legend text bold
            size: 12,
          }
        }
      },
    },
  };

  const prompts = [
    "test 1", "test 2", "test 3", "test4"
  ]

  const handleClick = (id) => {
    setSelected(id);
    setRiskScore(id)
    switch (id) {
      case 6:
        break;
      case 7:
        break;
      case 9:
        break;
      case 10:
        break;
    }

    return 30
  };

  // const handleSliderChange = (event) => {
  //   setSliderValue(event.target.value); // Update state with the slider value
  // };

  const [updateAlloc] = useUpdateAllocMutation();

  // make post request to get risky portfolio allocation 

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Pass the answer to the parent component
    // if (question.inputType === "number") {
    //   handleAnswer(Number(answer)); // Convert to a number
    // } else {
    //   handleAnswer(answer)
    // }
    
    const queryString = `?riskScore=${riskScore.toString()}`
      + `&years=${goalYear}` + `&months=${goalMonth}`
    try {
      await updateAlloc(queryString)
    } catch {
      console.error("Error updating allocation")
    }
    const answer = {
      done: question.next,
   }
    handleAnswer(answer)
    // setAnswer({}); // Clear the input for the next question

  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="h-20 flex justify-center items-center">
            <p className="text-2xl text-slate-600">
              Imagine you invested $1000 today & in 1 year it shrank to $800
            </p>
          </div>
          <div className="w-72 h-52">
            <Line data={chartData} options={options} />
          </div>
          <div className="h-16 flex justify-center items-center">
            <p className="text-2xl text-slate-900 font-semibold">
              How would you react?
            </p>
          </div>
        </div>
        {/* options button */}
        <div className="w-full flex pl-16 mb-8 ">
          <div className="flex flex-col gap-y-4 ">
            {[5, 6, 9, 10].map((id) => (
              <div className="flex items-center "
              onClick={() => handleClick(id)}
              >
                <div className={`border border-2 w-6 h-6 
         rounded-full flex justify-center items-center 
         ${selected === id ? " border-slate-600 bg-green-500 shadow-md shadow-green-300" : "border-slate-600"}`}
                  key={id}
                >
                  <button
                    key={id}
                    
                    className={`w-full h-full rounded-full border border-3 border-slate-500  ${selected === id ? "" : ""
                      }`}
                    style={{
                      WebkitAppearance: 'none',
                      MozAppearance: 'none'
                      // overflowY: 'auto',
                      // background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
                    }}
                  ></button>
                </div>
                <p className="text-xl ml-2  text-gray-600">
                  {(() => {
                    switch (id) {
                      case 5:
                        return "Sell everything";
                      case 6:
                        return "Sell some of it";
                      case 9:
                        return "Do nothing";
                      case 10:
                        return "Buy more";
                    }
                  })()}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center">
                    <NextButton />
        </div>

        {/* <div className={`h-max ${(selected === null) ? "hidden" : ""}`}> */}
        {/* risk tolerance slider  */}
        {/* <div className={`h-max ${(selected === null) ? "hidden" : ""}`}>
          <p className="text-lg text-slate-600 mb-4"> Drag the slider to adjust risk capacity</p>

          <div className="w-full h-14 rounded-lg flex flex-col items-center gap-y-2">
            <div className=" flex items-center justify-center">
              <p className="text-lg"> Current Risk capacity: {riskScore} </p>
            </div>
            <div className="flex items-center gap-x-3">
            <FontAwesomeIcon icon={faShield} className="text-green-600 h-6"/>
            <input
              type="range"
              min="1"
              max={`${(selected !== 1) ? "5" : "10"}`}
              step="1"
              defaultValue={riskScore}
              onChange={handleSliderChange}
              className="w-56 h-6 rounded-xl bg-gradient-to-r from-green-500 to-red-500 accent- bg-transparent"
              placeholder="Select an investment value"
              style={
                {
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                  // overflowY: 'auto',
                  // background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
                }
              }
            />
             <FontAwesomeIcon icon={faPepperHot} className="text-red-600 h-6"/>
           </div>
          </div>
          <div>
            <div className="my-10 flex flex-col gap-y-4">
              <div className="flex">
              <FontAwesomeIcon icon={faPepperHot} className="text-red-600 h-6 mr-4"/>
              <p className="text-lg"> More risk more possible reward</p>
              </div>
              <div className="flex">
              <FontAwesomeIcon icon={faShield} className="text-green-600 h-6 mr-4"/>
              <p className="text-lg"> I don't want my investment to fluctuate in value</p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="flex justify-center"> 
      <NextButton />
        </div> */}

        {/* </div> */}

      </form>
    </div>

  )
}
export default RiskIntro
